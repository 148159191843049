<template lang="pug">
  .pt-4.row
    .st-container.insights
      keep-alive
        router-view

</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'Insights',

  async mounted () {
    this.loadDepartments({ sort: 'name' })
    this.loadLocationsTree({ sort: 'name' })
    this.loadCompanyInfo()
   await this.loadSkillsTree({ lazy: true })
    if (this.lazySkillsTree) {
      this.loadSkillsTree({ lazy: false })
    }
  },
  computed: {
    ...mapGetters('insightsAllSkills', ['lazySkillsTree'])

  },

  methods: {
    ...mapActions('organization', [
      'loadDepartments'
    ]),

    ...mapActions('locations', [
      'loadLocationsTree'
    ]),

    ...mapActions('insightsAllSkills', [
      'loadSkillsTree',
      'loadCompanyInfo'
    ])

  }

}
</script>

<style lang="scss">

.insights {
  padding-right: 60px;

  &__detail-drawer {
    width: 100%;
    padding: 20px;

    &__header {
      margin-bottom: 10px;
    }
  }

  .insight-all-skills {

    &__action-filter-bar {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }

    &--reset {
      display: flex;
      align-items: center;
      margin: 0 30px ;
      height: 40px;
      text-align: center;
      font-size: 15px;
    }

    &--filter-appied {
      font-size: 15px;
    }

  &_preloader{
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(62vh + 136px);
  }
  }
}
</style>
